import React, {Component, lazy, Suspense} from "react";
import ReactGA from "react-ga";
import UserContext from "./components/UserContext";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import "./App.css";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import Pricing from "./components/Pricing/Pricing";
import Loader from "./components/Loader/Loader";

/*Sentry.init({
  dsn: "https://6f39efb48d04412e9ad8d597ee402bc8@sentry.io/1376094"
});*/

const PrivacyPolicy = lazy(() =>
    import("./components/PrivacyPolicy/PrivacyPolicy")
  ),
  LandingPage = lazy(() => import("./components/LandingPage/LandingPage")),
  TermsOfService = lazy(() =>
    import("./components/TermsOfService/TermsOfService")
  );

class App extends Component {
  constructor() {
    super();
    this.state = { reactGA: ReactGA, user: undefined };
    this.state.reactGA.initialize("UA-69629389-5");
  }

  componentDidMount() {
    // Determine if user is logged in
    // TODO: Change this in production
    fetch("https://dashboard.steinhq.com/api/user", { credentials: "include" })
      .then(res => {
        if (!res.ok) {
          return res.json().then(json => {
            const errorToThrow = new Error(json.error);
            errorToThrow.status = res.status;
            throw errorToThrow;
          });
        }

        return res.json();
      })
      .then(user => this.setState({ user }))
      .catch(error => {
        if (error.status !== 401) {
          console.log(error.message);
        }
      });
  }

  /*componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }*/

  render() {
    return (
      <BrowserRouter>
        <div className="flex flex-col min-h-screen">
          <UserContext.Provider value={this.state.user}>
            <div className="flex-1">
              <Switch>
                <Route exact path="/">
                  <div className="absolute top-0 left-0 bg-secondary h-1 z-30 w-full" />
                  <NavBar reactGA={this.state.reactGA} className="mt-16" />
                </Route>
                <Route>
                  <NavBar reactGA={this.state.reactGA} smallLogo />
                </Route>
              </Switch>
              <div className="mt-8">
                <Suspense fallback={<Loader />}>
                  <Route
                    exact
                    path="/"
                    render={() => {
                      return <LandingPage reactGA={this.state.reactGA} />;
                    }}
                  />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={PrivacyPolicy}
                  />
                  <Route
                    exact
                    path="/terms-of-service"
                    component={TermsOfService}
                  />
                  <Route exact path="/pricing" component={Pricing} />
                </Suspense>
              </div>
            </div>
            <Footer />
          </UserContext.Provider>
        </div>
      </BrowserRouter>
    );
  }
}

export default React.memo(App);
