import React, {Component, createRef} from "react";
import UserContext from "../UserContext";
import {Link} from "react-router-dom";
import SteinLogo from "../../assets/Logo.svg";
import MenuIcon from "../../assets/Menu Icon.svg";
import CTA from "../CTA/CTA";
import "./Navbar.css";
import Menu from "./Menu/Menu";

const links = {
  Documentation: "https://docs.steinhq.com",
  Pricing: { link: "/pricing", internal: true },
  "Open Source": "https://github.com/SteinHQ"
};

const linkElements = Object.keys(links).map(text => {
  if (links[text].internal) {
    return (
      <Link
        to={links[text].link}
        className="nav-link mr-8 text-greytext hover:text-accenttext"
        key={text}
      >
        {text}
      </Link>
    );
  }

  return (
    <a
      href={links[text]}
      className="nav-link mr-8 text-greytext hover:text-accenttext"
      key={text}
    >
      {text}
    </a>
  );
});

class NavBar extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      compressed: false,
      isMenuOpen: false
    };
    this.navBarRef = createRef();
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    this.handleCompression();
    window.addEventListener("scroll", this.handleCompression.bind(this), {
      passive: true
    });
  }

  toggleMenu() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  handleCompression() {
    if (
      !this.state.compressed &&
      this.navBarRef.current &&
      this.navBarRef.current.getBoundingClientRect().top <= 0 &&
      window.scrollY > 0
    ) {
      this.navBarRef.current.style.marginTop = 0;
      this.navBarRef.current.style.position = "fixed";
      this.setState({ compressed: true });
    } else if (
      this.state.compressed &&
      this.navBarRef.current &&
      window.scrollY <= 64
    ) {
      if (!this.props.smallLogo) {
        this.navBarRef.current.style.marginTop = "64px";
        this.navBarRef.current.style.position = "absolute";
      }
      this.setState({ compressed: false });
    }
  }

  render() {
    return (
      <>
        {this.props.smallLogo ? (
          <div style={{ height: "80px" }}>&nbsp;</div>
        ) : (
          <div style={{ height: "144px" }}>&nbsp;</div>
        )}
        <nav
          className={`flex absolute top-0 left-0 justify-between items-center w-full bg-white py-6 px-8 sm:px-16 md:px-20 h-auto z-20 ${
            this.state.compressed ? "shadow " : " "
          }${this.props.className}`}
          ref={this.navBarRef}
        >
          <Link to="/" className="flex items-center">
            <img
              src={SteinLogo}
              alt="Stein Logo"
              className={`nav-logo h-6 ${
                !(this.state.compressed || this.props.smallLogo) ? "md:h-8" : ""
              }`}
            />
          </Link>
          <div className="hidden lg:block">
            <div className="inline-block">{linkElements}</div>
            {/* If you change this button, remember to make the changes in Menu*/}
            {this.context && this.context._id ? (
              <CTA
                text="View dashboard"
                className="nav-cta rounded text-primary bg-accent shadow"
                tracker={() =>
                  this.props.reactGA.event({
                    category: "View Dashboard",
                    action: "Clicked",
                    label: "NavBar CTA"
                  })
                }
              />
            ) : (
              <CTA
                text="Sign up free"
                className="nav-cta rounded text-primary bg-accent shadow"
                tracker={() =>
                  this.props.reactGA.event({
                    category: "Sign Up",
                    action: "Clicked",
                    label: "NavBar CTA"
                  })
                }
              />
            )}
          </div>
          <button
            className="flex items-center lg:hidden focus:outline-none"
            onClick={this.toggleMenu}
            aria-label="Open Menu"
          >
            <img src={MenuIcon} className="h-8" alt="" />
          </button>
          <Menu
            links={links}
            isOpen={this.state.isMenuOpen}
            toggleOpenState={this.toggleMenu}
          />
        </nav>
      </>
    );
  }
}

export default NavBar;
