import React, {memo} from "react";
import "./PricingTable.css";
import CountUp from "react-countup";

const PricingTable = ({ plan, interval, actionButton }) => {
  return (
    <div
      className="flex flex-col text-center flex-1 p-6 mx-2 md:mx-4"
      key={plan.title}
    >
      <div>
        <h5 className="pricing-table-title font-normal text-secondary uppercase mt-4">
          {plan.title}
        </h5>
        <p className="text-greytext-light text-sm mt-0 mb-2 text-center">
          {plan.description}
        </p>
        <div className="flex justify-center text-secondary">
          <h6 className="pricing-table-dollar mr-1 text-lg">$</h6>
          <h3 className="pricing-table-price font-medium leading-none tracking-tight">
            {/* If .price is defined (for free plan), just show it. Else, show price based on interval. */}
            <CountUp
              decimals={1}
              start={
                plan.price === 0
                  ? plan.price
                  : interval !== "monthly"
                  ? plan.monthlyPrice
                  : plan.yearlyPrice
              }
              end={
                plan.price === 0
                  ? plan.price
                  : interval === "monthly"
                  ? plan.monthlyPrice
                  : plan.yearlyPrice
              }
            />
          </h3>
          <h6 className="self-end ml-1 text-lg">/mo</h6>
        </div>
        <div className="w-1/2 border mx-auto mt-4 opacity-75" />
        <div>
          {Object.keys(plan.details).map((detailName, index) => (
            <div
              className="text-xl text-secondary text-center mt-4"
              key={index}
            >
              {plan.details[detailName]}
              <p className="text-sm text-greytext mt-0">{detailName}</p>
            </div>
          ))}
        </div>
      </div>
      {actionButton && (
        <a
          href={actionButton.link}
          className="cta relative px-8 py-3 mt-2 bg-accent text-primary font-bold shadow rounded text-sm self-center"
        >
          {actionButton.text}
        </a>
      )}
    </div>
  );
};

export default memo(PricingTable);
