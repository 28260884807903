import React from "react";

const questionAnswers = {
  "What happens when I exceed my quota?": `You can trigger this when your sheet outgrows the supported row count,
          or when you exceed the number of requests allowed on your account. In
          both of these cases, further API requests will be denied with an 
    <a href="https://httpstatuses.com/429" className="animated-text-link">HTTP 429 Error</a> until the
          problem is fixed. API request counts reset every month.`,

  "Can I change plans at any time?": `Yes. Stein is a pay-as-you-go service and you can upgrade, downgrade,
          or cancel at any time. Open the "Account Details" tab on your
          dashboard and you'll see your options.`,

  "What if I want to cancel?": `If you ever decide that Stein isn't the best tool for your needs,
          simply cancel your subscription from your dashboard. No penalties, no
          further charges.`,

  "Are there provisions for enterprise accounts?": `For more resources and features, 
    <a href="mailto:hello@steinhq.com" className="animated-text-link">reach out!</a>`,

  "Got more questions?": `
    <a href="mailto:hello@steinhq.com" className="animated-text-link">Contact us</a>. We'd be glad to help.`
};

const FAQ = () => (
  <>
    <h2 className="text-6xl text-secondary text-center mx-auto mt-5 mb-6 font-medium tracking-wider">
      Q<span className="text-4xl font-normal">&</span>A
    </h2>
    <div className="flex w-3/4 mx-auto div flex-wrap">
      {Object.keys(questionAnswers).map((question, index) => (
        <div className="md:w-1/2 py-2 px-3" key={index}>
          <h3 className="text-xl text-primary font-medium">{question}</h3>
          <p
            className="mt-1"
            dangerouslySetInnerHTML={{ __html: questionAnswers[question] }}
          />
        </div>
      ))}
    </div>
  </>
);

export default FAQ;
