import React, {memo, useEffect, useState} from "react";
import UserContext from "../UserContext";
import PricingTable from "./PricingTable/PricingTable";
import IntervalToggle from "./IntervalToggle/IntervalToggle";
import FAQ from "./FAQ/FAQ";
import Loader from "../Loader/Loader";

const Pricing = () => {
  const [pricingPlans, setPricingPlans] = useState(null),
    [interval, specifyInterval] = useState("annual");

  useEffect(() => {
    // TODO: Change this
    fetch("https://dashboard.steinhq.com/api/pricing-plans")
      .then(res => {
        if (!res.ok) {
          return res.json().then(json => {
            const errorToThrow = new Error(json.error);
            errorToThrow.status = res.status;
            throw errorToThrow;
          });
        }

        return res.json();
      })
      .then(json => {
        setPricingPlans(json);
      })
      .catch(error => {
        if (error.status !== 401) {
          console.log(error.message);
        }
      });
  }, []);

  if (!pricingPlans) {
    return <Loader />;
  }

  return (
    <div className="flex justify-center flex-col">
      <h1 className="text-4xl md:text-5xl text-primary text-center mx-auto mb-4 mt-8">
        Simple, Minimal Pricing
      </h1>
      <IntervalToggle specifyInterval={specifyInterval} interval={interval} />
      <div className="flex flex-col sm:flex-row justify-center items-stretch w-full md:w-3/4 mx-auto">
        <UserContext.Consumer>
          {user => {
            return pricingPlans.map((plan, index) => {
              if (user && user.subscription.plan) {
                if (user.subscription.plan !== plan.dbIndex) {
                  return (
                    <PricingTable
                      plan={plan}
                      interval={interval}
                      key={index}
                      actionButton={{
                        text: "Switch to this plan",
                        link:
                          "https://dashboard.steinhq.com/dashboard/account-details/subscription"
                      }}
                    />
                  );
                }

                return (
                  <PricingTable plan={plan} key={index} interval={interval} />
                );
              }

              if (plan.dbIndex === 1) {
                return (
                  <PricingTable
                    plan={plan}
                    key={index}
                    actionButton={{
                      text: "Join for free",
                      link: "https://dashboard.steinhq.com/"
                    }}
                  />
                );
              }
              return (
                <PricingTable plan={plan} key={index} interval={interval} />
              );
            });
          }}
        </UserContext.Consumer>
      </div>
      <hr className="border border-gray-300 w-64 mt-12" />
      <FAQ />
    </div>
  );
};

export default memo(Pricing);
