import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="loader-element border-accent h-8 w-8" />
    </div>
  );
};

export default Loader;
