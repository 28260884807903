import React from "react";
import {Link} from "react-router-dom";
import LogoWhite from "../../assets/Logo White.svg";

const mainLinks = {
    Documentation: { href: "https://docs.steinhq.com" },
    Pricing: "/pricing",
    "Open Source": { href: "https://github.com/SteinHQ" },
    Contact: { href: "mailto:hello@steinhq.com" }
  },
  secondaryLinks = {
    "Privacy Policy": "/privacy-policy",
    "Terms of Service": "/terms-of-service"
  };

const mainLinkElements = [],
  secondaryLinkElements = [];

function generateLinkElement(name, value, classes) {
  let element;

  if (typeof value === "string") {
    element = (
      <Link className={classes} to={value} key={name}>
        {name}
      </Link>
    );
  } else {
    element = (
      <a className={classes} href={value.href} key={name}>
        {name}
      </a>
    );
  }

  return element;
}

Object.keys(mainLinks).forEach(name => {
  mainLinkElements.push(
    generateLinkElement(
      name,
      mainLinks[name],
      "text-white mr-5 animated-text-link"
    )
  );
});

Object.keys(secondaryLinks).forEach(name => {
  secondaryLinkElements.push(
    generateLinkElement(
      name,
      secondaryLinks[name],
      "text-greengreytext mr-5 animated-text-link text-sm"
    )
  );
});

const Footer = () => {
  return (
    <>
      <div className="bg-primary pt-12 pb-4 px-8 sm:px-16 md:px-20 mt-16 bottom-0 w-full">
        <Link to="/">
          <img src={LogoWhite} alt="Stein Logo" className="h-8" />
        </Link>
        <div className="mt-5 leading-loose sm:leading-normal">
          {mainLinkElements}
        </div>
        <hr className="border-t border-white mt-6 opacity-25" />
        <div className="mt-3">{secondaryLinkElements}</div>
        <div className="mt-3 text-greengreytext text-sm">
          © {new Date().getFullYear()} Stein
        </div>
      </div>
    </>
  );
};

export default Footer;
