import React from "react";
import UserContext from "../../UserContext";
import {Link} from "react-router-dom";
import CloseIcon from "../../../assets/Close Icon.svg";
import "./Menu.css";
import CTA from "../../CTA/CTA";

const linkClasses = "menu-link mr-16 text-white text-xl hover:text-accent";

const Menu = ({ links, isOpen, toggleOpenState }) => {
  return (
    <div
      className={`fixed top-0 right-0 h-screen w-full flex justify-end ${
        isOpen ? "" : "pointer-events-none"
      }`}
    >
      <div
        className={`menu-bar inline-block bg-primary absolute z-10 h-full pt-4 ${
          isOpen ? "shadow-lg menu-bar-open" : ""
        }`}
      >
        <button
          className={`menu-close-icon menu-opacity-ease inline-flex items-center my-6 p-2 rounded-full bg-white focus:outline-none ${
            isOpen ? "" : "opacity-0"
          }`}
          onClick={toggleOpenState}
        >
          <img src={CloseIcon} alt="Close" className="h-6" />
        </button>
        <div className="mx-10">
          {Object.keys(links).map((text, index) => {
            let element;

            if (links[text].internal) {
              element = (
                <Link to={links[text].link} className={linkClasses}>
                  {text}
                </Link>
              );
            } else {
              element = (
                <a href={links[text]} className={linkClasses}>
                  {text}
                </a>
              );
            }

            return (
              <div className="mb-4" key={index}>
                {element}
              </div>
            );
          })}
          <UserContext.Consumer>
            {user =>
              user && user._id ? (
                <CTA
                  text="View dashboard"
                  className="flex justify-center rounded text-primary bg-accent shadow"
                  tracker={() =>
                    this.props.reactGA.event({
                      category: "View Dashboard",
                      action: "Clicked",
                      label: "NavBar CTA"
                    })
                  }
                />
              ) : (
                <CTA
                  text="Sign up free"
                  className="flex justify-center rounded text-primary bg-accent shadow"
                  tracker={() =>
                    this.props.reactGA.event({
                      category: "Sign Up",
                      action: "Clicked",
                      label: "NavBar CTA"
                    })
                  }
                />
              )
            }
          </UserContext.Consumer>
        </div>
      </div>
      <div
        className={`h-screen menu-opacity-ease w-full bg-primary ${
          isOpen ? "opacity-50" : "opacity-0"
        }`}
        onClick={toggleOpenState}
      />
    </div>
  );
};

export default Menu;
