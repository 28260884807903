import React from "react";
import "./IntervalToggle.css";

const activeClasses = "bg-secondary text-white";

const IntervalToggle = ({ interval, specifyInterval }) => (
  <div className="rounded-full md:border border-secondary mx-auto text-secondary md:flex items-center">
    <button
      className={`interval-toggle-button-bg-transition md:pr-4 w-56 py-3 md:rounded-none md:rounded-l-full rounded-full focus:outline-none md:border-0 border border-secondary text-center md:text-right ${
        interval === "monthly" ? activeClasses : ""
      }`}
      onClick={() => specifyInterval("monthly")}
    >
      Billed Monthly
    </button>
    <button
      className={`interval-toggle-button-bg-transition flex items-center pl-4 w-56 py-3 mt-2 md:mt-0 md:rounded-none md:rounded-r-full rounded-full focus:outline-none md:border-0 border border-secondary ${
        interval === "annual" ? activeClasses : ""
      }`}
      onClick={() => specifyInterval("annual")}
    >
      Billed Annually
      <span
        className={`inline-block font-medium w-20 text-xs text-right ml-auto mr-4 ${
          interval === "annual" ? "text-accent" : "text-accenttext"
        }`}
      >
        (Save 20%)
      </span>
    </button>
  </div>
);

export default IntervalToggle;
